export const IdentityTypes = {
    PanCard: 1,
    AadhaarCard: 2,
    RationCard: 3,
    Passport: 4,
    DrivingLicense: 5,
    BankEStatement: 6,
    ElectricityBill: 7,
    Passbook: 8,
    ITR: 9,
    SalarySlip: 10,
    RegistrationCertificateOfBusinessforSelfEmployed: 11,
    RealtimeVideoPhoto: 20,
    InstaAadhaar: 21,
    InstaAadhaarLog: 22,
    InstaAadhaarXml: 23,
    InstaPanLog: 24,
    OSV: 25,
    CibilXML: 26,
    DownpaymentReceipt: 27,
    CAMReport: 28,
    ENachForm: 29,
    OtherDocument: 30,
    InstituteQueryDocuments: 31,
    AddressProofFaceImage: 32,
    PanFaceImage: 33,
    SelfieWithPanImage: 34,
    SelfiePanVideo: 35,
    InstituteFeeReceipt: 36,
    AlternateAddressProof: 37,
    MarksList: 38,
    CancelledCheque: 39,
    GasBill: 40
};