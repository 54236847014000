import axios from "axios";
import { DigiLockerConsentRequest } from "./models/DigiLockerConsentRequest";
import { StandardKycConsentRequest } from "./models/StandardKycConsentRequest";
import { ConsentValidationResult } from "./models/ConsentValidationResult";
import { SaveConsentResponse } from "./models/SaveConsentResponse";
import { VideoKycConsentRequest } from "../../../apis/models/VideoKycConsentRequest";
import baseUrls from "../../../config/service";

export function validateEkycConsentRequest(requestId: string) {
    return new Promise<ConsentValidationResult<DigiLockerConsentRequest>>((resolve, reject) => {

        const baseURL = baseUrls.nbfcKYC + "/digilocker-kyc/request/"+ requestId;
        // based on the request Id fetch the consent Link
        // if we failed to load throw an exception....
        axios
        .get(baseURL)
        .then((response) => {

          const validationResult = {
            ValidationResult : false,
            Request: undefined
          }

          if (response.data.Code === 0 && response.data.Errors.length === 0) {

            validationResult.ValidationResult = true;
            validationResult.Request = response.data.PayLoad;
            resolve(validationResult);
            return;
          }

          if (response.data.Code !== 0 && response.data.Errors.length > 0 ) {

              validationResult.ValidationResult = false;
              validationResult.Request = undefined;
              resolve(validationResult);
              return;
          }

          reject("We received unknown error with http status code : " + response.statusText);
        })
        .catch(e => reject("We received unknown error"));
  });
}  

export function validateStandardConsentRequest(requestId: string) {
  return new Promise<ConsentValidationResult<StandardKycConsentRequest>>((resolve, reject) => {
    const baseURL = baseUrls.nbfcKYC + "/standard-kyc/request/"+ requestId;
    // based on the request Id fetch the consent Link
    // if we failed to load throw an exception....
    axios.get(baseURL)
    .then((response) => {
        
      const validationResult = {
          ValidationResult : false,
          Request: undefined
      }

      if (response.data.Code === 0 && response.data.Errors.length === 0) {

        validationResult.ValidationResult = true;
        validationResult.Request = response.data.PayLoad;
        resolve(validationResult);
        return;
      }

      if (response.data.Code !== 0 && response.data.Errors.length > 0 ) {

          validationResult.ValidationResult = false;
          validationResult.Request = undefined;
          resolve(validationResult);
          return;
      }

      reject("We received unknown error with http status code : " + response.statusText);
    })
    .catch(e => reject("We received unknown error"));
  });
} 

export function validateVideoKycConsentRequest(requestId: string) {
  return new Promise<ConsentValidationResult<VideoKycConsentRequest>>((resolve, reject) => {
    const baseURL = baseUrls.nbfcKYC + "/video-kyc/request/meta/"+ requestId;
    // based on the request Id fetch the consent Link
    // if we failed to load throw an exception....
    axios.get(baseURL)
    .then((response) => {
        
      const validationResult = {
          ValidationResult : false,
          Request: undefined
      }

      if (response.data.Code === 0 && response.data.Errors.length === 0) {

        validationResult.ValidationResult = true;
        validationResult.Request = response.data.PayLoad;
        resolve(validationResult);
        return;
      }

      if (response.data.Code !== 0 && response.data.Errors.length > 0 ) {

          validationResult.ValidationResult = false;
          validationResult.Request = undefined;
          resolve(validationResult);
          return;
      }

      reject("We received unknown error with http status code : " + response.statusText);
    })
    .catch(e => reject("We received unknown error"));
  });
} 

export function saveConsentRequest(requestId: string) {
  return new Promise<SaveConsentResponse>((resolve, reject) => {

  const baseURL = baseUrls.nbfcKYC + "/digilocker-kyc/request/capture/consent";
  // based on the request Id fetch the consent Link
  // if we failed to load throw an exception....
  axios.post(baseURL, {RequestId : requestId}).then((response) => {

    if (response.data.Code === 0 && response.data.Errors.length === 0) {
      resolve({ 
        RequestId : requestId, 
        ConsentLink: response.data.PayLoad.ConsentLink, 
        ConsentStatus: response.data.PayLoad.ConsentCaptured ,
        ConsentRequestStatus: response.data.PayLoad.ConsentRequestStatus
      });
    } else {
      reject(response.data.Errors[0].Message);
    }
  });
});
}

export function saveStandardKycConsentRequest(requestId: string) {
  return new Promise<SaveConsentResponse>((resolve, reject) => {

  const baseURL = baseUrls.nbfcKYC + "/standard-kyc/request/capture/consent";
  // based on the request Id fetch the consent Link
  // if we failed to load throw an exception....
  axios.post(baseURL, {RequestId : requestId}).then((response) => {

    if (response.data.Code === 0 && response.data.Errors.length === 0) {
      resolve({ 
        RequestId : requestId, 
        ConsentStatus: response.data.PayLoad.ConsentCaptured, 
        ConsentLink: "",
        ConsentRequestStatus: response.data.PayLoad.ConsentRequestStatus
      });
    } else {
      reject(response.data.Errors[0].Message);
    }
  });
});
}

export function saveVideoKycConsentRequest(requestId: string) {
  return new Promise<SaveConsentResponse>((resolve, reject) => {

  const baseURL = baseUrls.nbfcKYC + "/video-kyc/request/capture/consent";
  // based on the request Id fetch the consent Link
  // if we failed to load throw an exception....
  axios.post(baseURL, {RequestId : requestId}).then((response) => {

    if (response.data.Code === 0 && response.data.Errors.length === 0) {
      resolve({ 
        RequestId : requestId, 
        ConsentStatus: response.data.PayLoad.ConsentCaptured, 
        ConsentLink: response.data.PayLoad.ConsentLink, 
        ConsentRequestStatus: response.data.PayLoad.ConsentRequestStatus
      });
    } else {
      reject(response.data.Errors[0].Message);
    }
  });
});
}

export function standardKycCallbackRequest(requestId: string) {
  return new Promise<{
      "ClientRedirectionUrl": string,
      "ClientReferenceId": string,
      "RequestId": string,
      "Status": string
  }>((resolve, reject) => {

  const baseURL = baseUrls.nbfcKYC + "/standard-kyc/request/callback/"+requestId;
    // based on the request Id fetch the consent Link
    // if we failed to load throw an exception....
    axios.get(baseURL).then((response) => {

      if (response.data.Code === 0 && response.data.Errors.length === 0) {
        resolve(response.data.PayLoad);
      } else {
        reject(response.data.Errors[0].Message);
      }
    }); 
  });
}

export function callbackConsentRequest( req : {requestId: string, search: any}) {
  return new Promise<{ requestId: string, redirectLink: string, redirectStatus: boolean }>((resolve, reject) => {

  const baseURL = baseUrls.nbfcKYC + "/digilocker-kyc/provider-callback/"+req.requestId+"?"+req.search.toString();
  // based on the request Id fetch the consent Link
  // if we failed to load throw an exception....
  axios.get(baseURL).then((response) => {

    if (response.data.Code === 0 && response.data.Errors.length === 0) {
      resolve({ 
        requestId : req.requestId, 
        redirectLink: response.data.PayLoad.url, 
        redirectStatus: response.data.PayLoad.status 
      });
    } else {
      reject(response.data.Errors[0].Message);
    }
  });
});
} 

export function getAccessTokenForHyperverge(requestId: string) {
  return new Promise<string>((resolve, reject) => {
  const baseURL = baseUrls.nbfcKYC + "/video-kyc/auth/token";
  axios.post(baseURL, {RequestId : requestId}).then((response) => {

    if (response.data.Code === 0 && response.data.Errors.length === 0) {
      resolve(response.data.PayLoad);
    } else {
      reject(response.data.Errors[0].Message);
    }
  });
});
}
