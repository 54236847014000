import serviceDev from './service.dev';
import serviceProduction from './service.prod';
import serviceStaging from './service.staging';
import serviceKarma from "./service.karma";
import envConfig from "../../env.json";

let config = serviceDev;

if (envConfig.ENV === 'dev')
{
    config = serviceDev;
} else if (envConfig.ENV === 'staging')
{
    config = serviceStaging;
} else if (envConfig.ENV === 'karma')
{
    config = serviceKarma;
} else
{
    config = serviceProduction;
}

export default config;
