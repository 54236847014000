import React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactCardFlip from "react-card-flip";

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Fab,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import { LoadingButton } from "@mui/lab";
import Modal from "@mui/material/Modal";
import Check from "@mui/icons-material/Check";

import { GetAllResourceResponse } from "../apis/models/GetAllResourceResponse";
import { getStandardKycRequestDetailsAsync } from "../redux/StandardKycSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { DocumentTypes } from "../models/DocumentTypes";
import { IdentityTypes } from "../models/IdentityTypes";
import {
    ResourceTypes,
    requiredNumberOfDocuments,
} from "../apis/models/ResourceTypes";

import sampleFront from "../images/illustrations/aadhaar_front_help_illustration.png";
import sampleBack from "../images/illustrations/aadhaar_back_help_illustration.png";
import sampleEAadhaar from "../images/illustrations/aadhaar_help_illustration.png";
import DocumentCropper from "../../../components/DocumentCropper";
import ImagePopover from "../../../components/ImagePopover";
import logo from "../images/edgrologo.png";
import "../styles/StandardKyc.scss";
import baseUrls from "../../../config/service";

type DocumentSelectorType = {
    resourceTitle: string;
    resourceType: string;
    documentType: number;
    identityType: number;
    isFront: boolean;
    frontFileName: string;
    isBack: boolean;
    backFileName: string;
};

function StandardKyc() {
    const [open, setOpen] = useState(false);
    const [selectedDocumentType, setSelectedDocumentType] =
        useState<DocumentSelectorType>();
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [frontFlipped, setFrontFlipped] = useState(true);
    const [backFlipped, setBackFlipped] = useState(true);
    const cropperRef1 = useRef();
    const cropperRef2 = useRef();
    const params = useParams();
    const requestId = String(params.RequestId);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { standardKycDetails, loading, error } = useAppSelector(
        (state: any) => state.standardKyc
    );

    const getStandardKycStatus = (
        standardKycDetails: GetAllResourceResponse
    ) => {
        const isPanUploaded = standardKycDetails
            ? standardKycDetails.Resources.find(
                  (r) => r.ResourceType == ResourceTypes.PAN_CARD
              ) != undefined
            : false;

        const addressProofs = standardKycDetails
            ? standardKycDetails.Resources.filter(
                  (r) => r.ResourceType != ResourceTypes.PAN_CARD
              )
            : null;

        const isAddressProofUploaded =
            addressProofs && addressProofs.length > 0
                ? requiredNumberOfDocuments(addressProofs[0].ResourceType) ==
                  addressProofs.length
                : false;

        return {
            isPanUploaded: isPanUploaded,
            isAddressProofUploaded: isAddressProofUploaded,
        };
    };

    useEffect(() => {
        dispatch(getStandardKycRequestDetailsAsync(requestId));
    }, []);

    useEffect(() => {
        if (uploaded) {
            closeModal();
            setUploaded(false);
        }
    }, [uploaded]);

    const documentUploadStatus = getStandardKycStatus(standardKycDetails);

    if (
        documentUploadStatus &&
        documentUploadStatus.isPanUploaded &&
        documentUploadStatus.isAddressProofUploaded
    ) {
        navigate("/browser/digilocker/kyc/callback/standard/" + requestId, {
            replace: true,
        });
    }

    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setSelectedDocumentType(undefined);
    };

    const uploadDocumentUploader = () => {
        if (
            selectedDocumentType &&
            selectedDocumentType.resourceType != "--SELECT--"
        ) {
            openModal();
        }
    };

    const uploadPanDocumentUploader = () => {
        setSelectedDocumentType({
            resourceTitle: "PAN CARD",
            resourceType: "PAN_CARD",
            isFront: true,
            frontFileName: "PAN_FRONT.jpeg",
            isBack: false,
            backFileName: "",
            documentType: DocumentTypes.PhotoIdentityProof,
            identityType: IdentityTypes.PanCard,
        });
        openModal();
    };

    const uploadRequest = async (blob: any, name: any, queryParams: any) => {
        let formData = new FormData();
        formData.append("upload_file", blob, name);
        let url = baseUrls.documents + "/standard-kyc/upload/" + requestId;

        Object.keys(queryParams).forEach((key) => {
            formData.append(key, queryParams[key]);
        });

        return fetch(url, {
            body: formData,
            headers: {},
            method: "POST",
        });
    };

    const uploadClick = async (option: DocumentSelectorType) => {
        const promises = [];
        let image1 = undefined;
        let image2 = undefined;

        if (
            cropperRef1.current &&
            option.isFront &&
            (cropperRef1.current as any).hasFileSelected()
        ) {
            image1 = await (cropperRef1.current as any).cropImage();
        }

        if (
            cropperRef2.current &&
            option.isBack &&
            (cropperRef2.current as any).hasFileSelected()
        ) {
            image2 = await (cropperRef2.current as any).cropImage();
        }

        if (
            (option.isFront && option.isBack && image1 && image2) ||
            (option.isFront && !option.isBack && image1)
        ) {
            try {
                setUploading(true);

                if (image1) {
                    const uploadImageRequest = uploadRequest(
                        image1,
                        option.frontFileName,
                        {
                            RequestId: requestId,
                            ResourceType: option.resourceType,
                            SQNo: 1,
                            DocumentType: option.documentType,
                            IdentityType: option.identityType,
                        }
                    );
                    promises.push(uploadImageRequest);
                }

                if (image2) {
                    const uploadImageRequest = uploadRequest(
                        image2,
                        option.backFileName,
                        {
                            RequestId: requestId,
                            ResourceType: option.resourceType,
                            SQNo: 2,
                            DocumentType: option.documentType,
                            IdentityType: option.identityType,
                        }
                    );

                    promises.push(uploadImageRequest);
                }

                await Promise.all(promises);
                setUploaded(true);
                setUploading(false);
            } catch (ex) {
                console.log(ex);
                setUploading(false);
            }

            dispatch(getStandardKycRequestDetailsAsync(requestId));
        }
    };

    const standardKycDocumentCapture = (options: DocumentSelectorType) => {
        return (
            <Modal
                open={open}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className="document-input-modal"
            >
                <Card className="modal-card">
                    <CardHeader
                        action={
                            <Fab
                                color="primary"
                                aria-label="add"
                                onClick={closeModal}
                                className="close-button"
                                size="small"
                            >
                                <CloseIcon />
                            </Fab>
                        }
                        title={options.resourceTitle + " Details"}
                        className="header-card"
                    />

                    <CardContent>
                        {!options.isBack && options.isFront && (
                            <div className="document-upload-section">
                                <DocumentCropper
                                    key="pan"
                                    aspectRatio={4 / 2.4}
                                    testId="pan"
                                    documentText=""
                                    title={
                                        <div style={{ padding: 16 }}>
                                            <p>
                                                <ImageIcon
                                                    style={{ fontSize: 24 }}
                                                    type="file-image"
                                                />
                                            </p>
                                            <p>
                                                Upload {options.resourceTitle}{" "}
                                                Front (Only)
                                            </p>
                                        </div>
                                    }
                                    ref={cropperRef1}
                                />
                            </div>
                        )}

                        {options.isBack && options.isFront && (
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sm={6}
                                    alignContent={"center"}
                                >
                                    <div className="document-upload-section">
                                        <DocumentCropper
                                            key="front"
                                            aspectRatio={4 / 2.4}
                                            testId="front"
                                            documentText={
                                                options.resourceTitle + " Front"
                                            }
                                            title={
                                                <div style={{ padding: 16 }}>
                                                    <p>
                                                        <ImageIcon
                                                            style={{
                                                                fontSize: 24,
                                                            }}
                                                            type="file-image"
                                                        />
                                                    </p>
                                                    <p>
                                                        Upload{" "}
                                                        {options.resourceTitle}{" "}
                                                        Front (Image Only)
                                                    </p>
                                                </div>
                                            }
                                            ref={cropperRef1}
                                        />
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sm={6}
                                    alignContent={"center"}
                                >
                                    <div className="document-upload-section">
                                        <DocumentCropper
                                            key="back"
                                            aspectRatio={4 / 2.4}
                                            testId="back"
                                            documentText={
                                                options.resourceTitle + " Back"
                                            }
                                            title={
                                                <div style={{ padding: 16 }}>
                                                    <p>
                                                        <ImageIcon
                                                            style={{
                                                                fontSize: 24,
                                                            }}
                                                            type="file-image"
                                                        />
                                                    </p>
                                                    <p>
                                                        Upload{" "}
                                                        {options.resourceTitle}{" "}
                                                        Back (Image Only)
                                                    </p>
                                                </div>
                                            }
                                            ref={cropperRef2}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        )}

                        <Grid
                            container
                            spacing={0}
                            columnSpacing={{ xs: 0, sm: 0, md: 0 }}
                        >
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="outlined"
                                    loading={uploading}
                                    onClick={() =>
                                        uploadClick({
                                            resourceTitle:
                                                options.resourceTitle,
                                            resourceType: options.resourceType,
                                            documentType: options.documentType,
                                            identityType: options.identityType,
                                            isFront: options.isFront,
                                            frontFileName:
                                                options.frontFileName,
                                            isBack: options.isBack,
                                            backFileName: options.backFileName,
                                        })
                                    }
                                    loadingIndicator={
                                        uploading
                                            ? "Uploading..."
                                            : "Upload Pan"
                                    }
                                >
                                    Upload {options.resourceTitle}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Modal>
        );
    };

    const aadhaarContent = (options: DocumentSelectorType) => {
        return (
            <Grid>
                <Modal
                    open={open}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                    className="document-input-modal"
                >
                    <Card className="modal-card">
                        <CardHeader
                            action={
                                <Fab
                                    color="primary"
                                    aria-label="add"
                                    onClick={closeModal}
                                    className="close-button"
                                    size="small"
                                >
                                    <CloseIcon />
                                </Fab>
                            }
                            title="Aadhaar Details"
                            className="header-card"
                        />

                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sm={6}
                                    alignContent={"center"}
                                >
                                    <Button
                                        id="show-me-how-front-btn"
                                        sx={{ textTransform: "initial" }}
                                        onClick={() => {
                                            setFrontFlipped(!frontFlipped);
                                        }}
                                    >
                                        Show me how?
                                    </Button>
                                    <ReactCardFlip isFlipped={frontFlipped}>
                                        <img
                                            alt="sampleFront"
                                            style={{ maxWidth: "100%" }}
                                            key="front"
                                            src={sampleFront}
                                        />
                                        <DocumentCropper
                                            key="front"
                                            aspectRatio={4 / 2.4}
                                            testId="front"
                                            documentText="Aadhaar Front"
                                            title={
                                                <div style={{ padding: 16 }}>
                                                    <p>
                                                        <ImageIcon
                                                            style={{
                                                                fontSize: 24,
                                                            }}
                                                            type="file-image"
                                                        />
                                                    </p>
                                                    <p>
                                                        Upload Aadhaar Front
                                                        (Image Only)
                                                    </p>
                                                </div>
                                            }
                                            ref={cropperRef1}
                                        />
                                    </ReactCardFlip>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sm={6}
                                    alignContent={"center"}
                                >
                                    <Button
                                        id="show-me-how-back-btn"
                                        sx={{ textTransform: "initial" }}
                                        onClick={() => {
                                            setBackFlipped(!backFlipped);
                                        }}
                                    >
                                        Show me how?
                                    </Button>
                                    <ReactCardFlip isFlipped={backFlipped}>
                                        <img
                                            alt="sampleBack"
                                            key="back"
                                            style={{ maxWidth: "100%" }}
                                            src={sampleBack}
                                        />
                                        <DocumentCropper
                                            key="back"
                                            aspectRatio={4 / 2.4}
                                            testId="back"
                                            documentText="Aadhaar Back"
                                            title={
                                                <div style={{ padding: 16 }}>
                                                    <p>
                                                        <ImageIcon
                                                            style={{
                                                                fontSize: 24,
                                                            }}
                                                            type="file-image"
                                                        />
                                                    </p>
                                                    <p>
                                                        Upload Aadhaar Front
                                                        (Image Only)
                                                    </p>
                                                </div>
                                            }
                                            ref={cropperRef2}
                                        />
                                    </ReactCardFlip>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={0}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                rowSpacing={{ xs: 2, sm: 2, md: 2 }}
                            >
                                <Grid item>
                                    <LoadingButton
                                        variant="outlined"
                                        loading={uploading}
                                        onClick={() =>
                                            uploadClick({
                                                resourceTitle:
                                                    options.resourceTitle,
                                                resourceType:
                                                    options.resourceType,
                                                documentType:
                                                    options.documentType,
                                                identityType:
                                                    options.identityType,
                                                isFront: options.isFront,
                                                frontFileName:
                                                    options.frontFileName,
                                                isBack: options.isBack,
                                                backFileName:
                                                    options.backFileName,
                                            })
                                        }
                                        loadingIndicator={
                                            uploading
                                                ? "Uploading..."
                                                : "Upload Aadhaar"
                                        }
                                    >
                                        Upload Aadhaar
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12} md={6} sm={6}>
                                    <ImagePopover
                                        imagesrc={sampleEAadhaar}
                                        buttonName="How to upload E-Aadhaar?"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Modal>
            </Grid>
        );
    };

    const documentSelector = () => {
        // Setting the defuault
        if (!selectedDocumentType) {
            setSelectedDocumentType({
                resourceTitle: "ADHAAR CARD",
                resourceType: "AADHAR_CARD",
                isFront: true,
                frontFileName: "ADHAAR_FRONT.jpeg",
                isBack: true,
                backFileName: "ADHAAR_BACK.jpeg",
                documentType: DocumentTypes.AddressProof,
                identityType: IdentityTypes.AadhaarCard,
            });
        }

        const handleChange = (event: SelectChangeEvent) => {
            const documentType = event.target.value;

            if (documentType === "AADHAR_CARD") {
                setSelectedDocumentType({
                    resourceTitle: "ADHAAR CARD",
                    resourceType: "AADHAR_CARD",
                    isFront: true,
                    frontFileName: "ADHAAR_FRONT.jpeg",
                    isBack: true,
                    backFileName: "ADHAAR_BACK.jpeg",
                    documentType: DocumentTypes.AddressProof,
                    identityType: IdentityTypes.AadhaarCard,
                });
            }

            if (documentType === "DRIVING_LICENSE") {
                setSelectedDocumentType({
                    resourceTitle: "DRIVING LICENSE",
                    resourceType: "DRIVING_LICENSE",
                    isFront: true,
                    frontFileName: "DL_FRONT.jpeg",
                    isBack: false,
                    backFileName: "",
                    documentType: DocumentTypes.AddressProof,
                    identityType: IdentityTypes.DrivingLicense,
                });
            }

            if (documentType === "PASSPORT") {
                setSelectedDocumentType({
                    resourceTitle: "Passport",
                    resourceType: "PASSPORT",
                    isFront: true,
                    frontFileName: "PASSPORT_FRONT.jpeg",
                    isBack: true,
                    backFileName: "PASSPORT_BACK.jpeg",
                    documentType: DocumentTypes.AddressProof,
                    identityType: IdentityTypes.Passport,
                });
            }
        };

        return (
            <React.Fragment>
                <Grid>
                    <img
                        alt="edgro-logo"
                        src={logo}
                        className="edgro-logo"
                    ></img>
                </Grid>

                <Grid container className="standard-kyc-container">
                    <Paper className="kyc-paper" elevation={3}>
                        <div className="upload-selection-section">
                            <span className="title">Identity Proof : </span>
                            {!documentUploadStatus.isPanUploaded && (
                                <Button
                                    variant="contained"
                                    disabled={
                                        documentUploadStatus.isPanUploaded
                                    }
                                    onClick={uploadPanDocumentUploader}
                                    className="upload-button"
                                >
                                    {" "}
                                    Upload Pan{" "}
                                </Button>
                            )}
                            {documentUploadStatus.isPanUploaded && (
                                <IconButton className="checked-button">
                                    <Check />
                                </IconButton>
                            )}
                        </div>

                        <div className="upload-selection-section">
                            <span className="title">Address Proof : </span>
                            {!documentUploadStatus.isAddressProofUploaded && (
                                <FormControl className="document-type-input">
                                    <InputLabel id="demo-simple-select-helper-label">
                                        Document Type
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={
                                            selectedDocumentType &&
                                            selectedDocumentType.resourceType
                                                ? selectedDocumentType.resourceType
                                                : "AADHAR_CARD"
                                        }
                                        label="Document Type"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"AADHAR_CARD"}>
                                            AADHAR CARD
                                        </MenuItem>
                                        <MenuItem value={"DRIVING_LICENSE"}>
                                            DRIVING LICENSE
                                        </MenuItem>
                                        <MenuItem value={"PASSPORT"}>
                                            PASSPORT
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                            {!documentUploadStatus.isAddressProofUploaded && (
                                <Button
                                    variant="contained"
                                    onClick={uploadDocumentUploader}
                                    className="upload-button"
                                >
                                    {" "}
                                    Upload Document
                                </Button>
                            )}
                            {documentUploadStatus.isAddressProofUploaded && (
                                <IconButton className="checked-button">
                                    <Check />
                                </IconButton>
                            )}
                        </div>
                    </Paper>
                </Grid>
            </React.Fragment>
        );
    };

    const loadContent = () => {
        if (requestId && selectedDocumentType) {
            if (selectedDocumentType.resourceType === "AADHAR_CARD") {
                return aadhaarContent(selectedDocumentType);
            } else {
                return standardKycDocumentCapture(selectedDocumentType);
            }
        }
    };

    return (
        <React.Fragment>
            {requestId && documentSelector()}
            {loadContent()}
        </React.Fragment>
    );
}

export default StandardKyc;
