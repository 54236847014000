import { createBrowserRouter } from 'react-router-dom';
import Home from "./home";
import Error from "./error";
import Consent from './features/consent/pages/Consent';
import ConsentCallback from "./features/consent/pages/ConsentCallback";
import StandardKyc from "./features/standardkyc/pages/StandardKyc";
 
const routes = createBrowserRouter([
{
    path: '/',
    errorElement: <Error />,
    children: [
        {
            index: true,
            element: <Home />    
        },
        {
            path: '/browser/standard/kyc/:RequestId',
            element: <StandardKyc />
        },
        {
            path: '/browser/digilocker/kyc/consent/:kycType/:RequestId',
            element: <Consent />
        },
        {
            path: '/browser/:kycType/consent/:RequestId',
            element: <Consent />
        },
        {
            path: '/browser/digilocker/kyc/callback/:kycType/:trnxId',
            element: <ConsentCallback />
        }
    ] 
}
]);
  
export default routes;