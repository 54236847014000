const nbfcKYC = 'https://api.edgrofin.com/api-gateway-edgrofin/private';
const documents = 'https://api.edgrofin.com/kyc-stack/private';
const propelld = 'https://propelld.com';

const baseUrls = {
    documents,
    nbfcKYC,
    propelld
};

export default baseUrls;
