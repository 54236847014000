const nbfcKYC = 'https://staging.api.edgrofin.com/api-gateway-edgrofin/private';
const documents = 'https://staging.api.edgrofin.com/kyc-stack/private';
const propelld = 'https://karma.dashboard.propelld.com';

const baseUrls = {
    documents,
    nbfcKYC,
    propelld
};

export default baseUrls;
