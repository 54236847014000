import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import { Alert, Card, CardContent, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { ConsentRequestStatus } from "../apis/models/ConsentRequestStatus";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    validateEkycConsentRequestAsync,
    validateStandardConsentRequestAsync,
    setConsent,
    saveConsentRequestAsync,
    saveStandardKycConsentRequestAsync,
    ConsentState,
    validateVideoKycConsentRequestAsync,
    saveVideoKycConsentRequestAsync,
    getHypervergeAccessToken,
} from "../redux/ConsentSlice";

import logo from "../images/edgrologo.png";
import "../styles/Consent.scss";
import envConfig from "../../../env.json";
import baseUrls from "../../../config/service";

declare global {
    interface Window {
        HyperKycConfig: any;
        HyperKYCModule: any;
    }
}

function Consent() {
    const [accept, setAccept] = useState(false);
    const [geolocation, setGeolocation] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [modalTilte, setModalTitle] = useState("");
    const [countDown, setCountdown] = useState(0);
    const checkBox = useRef(null);
    const proceedButton = useRef(null);
    const dispatch = useAppDispatch();
    const {
        consent,
        StandardKycConsentValidationResult,
        DigiLockerConsentValidationResult,
        SaveConsentResponse,
        loading,
        error,
        VideoKycConsentValidationResult,
        hypervergeAccessToken,
    } = useAppSelector(
        (state: any): ConsentState => state.consent as ConsentState
    );

    const navigate = useNavigate();
    const params = useParams();
    const kycType = params.kycType;
    const requestId = params.RequestId;

    const handler = (HypervergeKycResult: any) => {
        let baseUrl = baseUrls.propelld;
        let landingPageUrl = "/dashboard/ongoing-application";
        switch (HypervergeKycResult?.status) {
            case "user_cancelled":
            case "error":
            case "auto_declined":
                setModalTitle("Failed To Complete Video Verification");
                setOpen(true);
                break;
            case "auto_approved":
                setModalTitle("Video Verification Done!");
                setOpen(true);
                landingPageUrl = `/onboarding/video-verification/${VideoKycConsentValidationResult.Request?.ClientReferenceId}/result/${requestId}?status=success&provider=hyperverge`;
                break;
            case "needs_review":
                setModalTitle("Video Verification Is Under Reivew");
                setOpen(true);
                break;
            default:
                setOpen(true);
                break;
        }
        setTimeout(
            () => window.location.replace(baseUrl + landingPageUrl),
            5000
        );
        setCountdown(5);
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setGeolocation({
                Latitude: position.coords.latitude,
                Longitude: position.coords.longitude,
            } as any);
        });
    }, []);

    useEffect(() => {
        if (!DigiLockerConsentValidationResult && kycType === "ekyc") {
            dispatch(
                validateEkycConsentRequestAsync(requestId as string) as any
            );
        }

        if (!StandardKycConsentValidationResult && kycType === "standard") {
            dispatch(
                validateStandardConsentRequestAsync(requestId as string) as any
            );
        }

        if (!VideoKycConsentValidationResult && kycType === "videokyc") {
            dispatch(
                validateVideoKycConsentRequestAsync(requestId as string) as any
            );
        }
    }, [
        requestId,
        kycType,
        dispatch,
        params,
        StandardKycConsentValidationResult,
        DigiLockerConsentValidationResult,
        VideoKycConsentValidationResult,
    ]);

    useEffect(() => {
        if (
            consent &&
            accept &&
            SaveConsentResponse &&
            SaveConsentResponse.ConsentStatus &&
            SaveConsentResponse.ConsentLink &&
            kycType === "ekyc"
        ) {
            window.location.replace(SaveConsentResponse.ConsentLink);
        }

        if (
            consent &&
            accept &&
            SaveConsentResponse &&
            SaveConsentResponse.ConsentStatus &&
            kycType === "standard"
        ) {
            navigate("/browser/standard/kyc/" + requestId, { replace: true });
        }

        if (
            consent &&
            accept &&
            SaveConsentResponse &&
            SaveConsentResponse.ConsentStatus &&
            kycType === "videokyc" &&
            !hypervergeAccessToken
        ) {
            dispatch(getHypervergeAccessToken(requestId as string));
        }
    }, [accept, SaveConsentResponse, consent]);

    useEffect(() => {
        if (SaveConsentResponse && SaveConsentResponse.ConsentStatus) {
            setAccept(consent);
        }
    }, [SaveConsentResponse, consent]);

    useEffect(() => {
        if (hypervergeAccessToken) {
            const workflowId =
                envConfig.ENV === "live"
                    ? "edgro_vkyc_platform_prod"
                    : "edgro_vkyc_platform";
            const hyperKycConfig = new window.HyperKycConfig(
                hypervergeAccessToken,
                workflowId,
                requestId
            );
            hyperKycConfig.setInputs({
                ...VideoKycConsentValidationResult.Request?.VerificationMeta,
            });
            window.HyperKYCModule.launch(hyperKycConfig, handler);
        }
    }, [hypervergeAccessToken, requestId, VideoKycConsentValidationResult]);

    useEffect(() => {
        if (countDown > 0) {
            setTimeout(() => {
                setCountdown(countDown - 1);
            }, 1000);
        }
    }, [countDown]);

    const consentCheck = () => {
        const isChecked = (checkBox.current as any).checked;
        dispatch(setConsent(isChecked));
    };

    const proceed = () => {
        if (consent) {
            if (kycType === "ekyc") {
                dispatch(saveConsentRequestAsync(requestId as string) as any);
            }

            if (kycType === "standard") {
                dispatch(
                    saveStandardKycConsentRequestAsync(
                        requestId as string
                    ) as any
                );
            }

            if (kycType === "videokyc") {
                dispatch(
                    saveVideoKycConsentRequestAsync(requestId as string) as any
                );
            }
        }
    };

    const getValidationResult = (): Boolean | undefined => {
        if (kycType === "standard" && StandardKycConsentValidationResult) {
            return StandardKycConsentValidationResult.ValidationResult;
        }

        if (kycType === "ekyc" && DigiLockerConsentValidationResult) {
            return DigiLockerConsentValidationResult.ValidationResult;
        }

        if (kycType === "videokyc" && VideoKycConsentValidationResult) {
            return VideoKycConsentValidationResult.ValidationResult;
        }

        return false;
    };

    return (
        <React.Fragment>
            <Grid className="header">
                <img alt="logo" src={logo} className="edgro-logo"></img>
            </Grid>
            <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className="consent-container"
            >
                {((StandardKycConsentValidationResult &&
                    !getValidationResult()) ||
                    error) && (
                    <Grid item xs={6} md={4} className="error-badge">
                        <Alert severity="error">
                            We could not able to verify your consent request.
                            Please contact support team.
                        </Alert>
                    </Grid>
                )}
                {loading === "loading" && <CircularProgress />}
                {getValidationResult() &&
                    !(
                        StandardKycConsentValidationResult &&
                        StandardKycConsentValidationResult.Request &&
                        [
                            ConsentRequestStatus.USER_ACTION_COMPLETED,
                            ConsentRequestStatus.DONE,
                        ].includes(
                            StandardKycConsentValidationResult.Request.Status
                        )
                    ) &&
                    loading === "idle" && (
                        <Grid item xs={6} md={4} className="consent-content">
                            <Card raised={true} className="consent-card">
                                <CardContent className="card-content">
                                    <Grid>
                                        <div className="user-consent-selection">
                                            <span>
                                                <input
                                                    type="checkbox"
                                                    onChange={consentCheck}
                                                    ref={checkBox}
                                                    checked={consent}
                                                    className="input-checkbox"
                                                ></input>
                                            </span>
                                            <span className="consent-desc">
                                                I provide consent to Edgro
                                                Finance Private Limited to
                                                access/download my
                                                CKYC/Digilocker documents and
                                                share them with Bluebear
                                                Technology Private Limited and
                                                its lending partners.
                                            </span>
                                        </div>
                                    </Grid>
                                    <Grid className="action-container">
                                        <Button
                                            variant="contained"
                                            ref={proceedButton}
                                            disabled={
                                                consent === false ||
                                                (SaveConsentResponse &&
                                                    SaveConsentResponse.ConsentStatus)
                                            }
                                            onClick={proceed}
                                            className="proceed-button"
                                        >
                                            {" "}
                                            Proceed{" "}
                                        </Button>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                {getValidationResult() &&
                    StandardKycConsentValidationResult &&
                    StandardKycConsentValidationResult.Request &&
                    [
                        ConsentRequestStatus.USER_ACTION_COMPLETED,
                        ConsentRequestStatus.DONE,
                    ].includes(
                        StandardKycConsentValidationResult.Request.Status
                    ) &&
                    loading === "idle" && (
                        <Grid item xs={6} md={4} className="error-badge">
                            <Alert severity="error">
                                The request has already been processed or there
                                is problem with this request. Please contact
                                support team.
                            </Alert>
                        </Grid>
                    )}
            </Grid>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {modalTilte && (
                    <DialogTitle id="alert-dialog-title">
                        {modalTilte}
                    </DialogTitle>
                )}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You will be redirected back to Propelld in {countDown}{" "}
                        seconds.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default Consent;
