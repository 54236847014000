import axios from "axios";
import { GetAllResourceResponse } from "./models/GetAllResourceResponse";
import baseUrls from "../../../config/service";

export function getStandardKycRequestDetails(requestId: string) {
  return new Promise<GetAllResourceResponse>((resolve, reject) => {

  const baseURL = baseUrls.nbfcKYC + "/standard-kyc/meta/"+requestId;
  // based on the request Id fetch the consent Link
  // if we failed to load throw an exception....
  axios.get(baseURL).then((response) => {

    if (response.data.Code === 0 && response.data.Errors.length === 0) {
      resolve(response.data.PayLoad.Result);
    } else {
      reject(response.data.Errors[0].Message);
    }
  });
});
}
