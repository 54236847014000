import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, ButtonGroup } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import AddIcon from "@mui/icons-material/Add";

import "cropperjs/dist/cropper.css";
import "./styles/DocumentCropper.scss";

const DocumentCropper = forwardRef((props: any, ref: any) => {
    const [src, setSrc] = useState(undefined);
    const cropperRef = useRef<ReactCropperElement>(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                cropImage: cropImage,
                src: src,
                hasFileSelected: hasFileSelected,
            };
        },
        [src]
    );

    const onChange = (e: any) => {
        e.preventDefault();
        if (e.target.files.length < 1) {
            return;
        }
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            // console.log("SRC : ", reader.result)
            setSrc(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
    };

    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            console.log(cropper.getCroppedCanvas().toDataURL());
        }
    };

    const onZoomIn = () => {
        cropperRef.current?.cropper.zoom(0.1);
    };

    const onZoomOut = () => {
        cropperRef.current?.cropper.zoom(-0.1);
    };

    const onRotateLeft = () => {
        cropperRef.current?.cropper.rotate(-90);
    };

    const onRotateRight = () => {
        cropperRef.current?.cropper.rotate(90);
    };

    const onMoveLeft = () => {
        cropperRef.current?.cropper.move(-10, 0);
    };

    const onMoveRight = () => {
        cropperRef.current?.cropper.move(10, 0);
    };

    const cropImageBase64 = async (
        width: number,
        height: number,
        quality = 0.9
    ) => {
        return new Promise((res, rej) => {
            if (!src) {
                rej("NoFile");
                return;
            }
            if (
                typeof cropperRef.current?.cropper.getCroppedCanvas() ===
                "undefined"
            ) {
                rej("CropperUndefined");
                return;
            }
            let data = cropperRef.current?.cropper
                .getCroppedCanvas()
                .toDataURL("image/jpeg", quality);
            res(data);
        });
    };

    const cropImage = async (width: number, height: number, quality = 0.9) => {
        return new Promise((res, rej) => {
            if (!src) {
                rej("NoFile");
            }
            if (
                typeof cropperRef.current?.cropper.getCroppedCanvas() ===
                "undefined"
            ) {
                rej("CropperUndefined");
            }
            if (!width || !height) {
                cropperRef.current?.cropper.getCroppedCanvas().toBlob(
                    (blob) => {
                        res(blob);
                    },
                    "image/jpeg",
                    quality
                );
            } else {
                cropperRef.current?.cropper
                    .getCroppedCanvas({ width: width, height: height })
                    .toBlob(
                        (blob) => {
                            res(blob);
                        },
                        "image/jpeg",
                        quality
                    );
            }
        });
    };

    const hasFileSelected = () => (src ? true : false);

    return (
        <div className="crop-uploader-container">
            {!src && (
                <div className="upload-placeholder">
                    <div className="uploading-input">
                        <div className="input-section">
                            {props.title || "Select File"}

                            <div className="file-input-overlay">
                                <input
                                    className="file-input"
                                    id={props.testId}
                                    data-testid={props.testId}
                                    onChange={onChange}
                                    type="file"
                                    accept="image/*"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {src && (
                <div className="document-cropper-section">
                    <span className="document-desc">
                        {props.documentText}
                    </span>
                    <Cropper
                        src={src}
                        dragMode="move"
                        cropBoxMovable={true}
                        cropBoxResizable={true}
                        aspectRatio={props.aspectRatio || 3.37 / 2.125}
                        guides={false}
                        autoCrop={true}
                        background={true}
                        allowTransparency={false}
                        style={{ height: "360px" }}
                        initialAspectRatio={16 / 9}
                        crop={onCrop}
                        ref={cropperRef}
                    />
                    <div className="document-options">
                        <ButtonGroup
                          className="toggle-button-groups"
                        >
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={onMoveLeft}
                            >
                                <ArrowBackIcon />
                            </Button>

                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={onMoveRight}
                            >
                                <ArrowForwardIcon />
                            </Button>

                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={onZoomIn}
                            >
                                <AddIcon />
                            </Button>

                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={onZoomOut}
                            >
                                <RemoveIcon />
                            </Button>

                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={onRotateLeft}
                            >
                                <UndoIcon />
                            </Button>

                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={onRotateRight}
                            >
                                <RedoIcon />
                            </Button>
                        </ButtonGroup>
                        <label
                            htmlFor={"filePicker_" + props.testId}
                            className="btn-custom-file-uploder"
                        >
                            Change File
                        </label>

                        <input
                            id={"filePicker_" + props.testId}
                            type="file"
                            onChange={onChange}
                            accept="image/*"
                            className="change-document-input"
                        />
                    </div>
                </div>
            )}
        </div>
    );
});

export default DocumentCropper;
