export enum ResourceTypes
{
    VIDEO_KYC_FACE = "VIDEO_KYC_FACE",
    VIDEO_KYC_VIDEO = "VIDEO_KYC_VIDEO",
    ADHAAR_FILE = "ADHAAR_FILE",
    ADHAAR_XML = "ADHAAR_XML",
    ADHAAR_IMAGE = "ADHAAR_IMAGE",
    DRIVING_LICENSE = "DRIVING_LICENSE",
    PAN_CARD = "PAN_CARD",
    PASSPORT = "PASSPORT"
}

export const requiredNumberOfDocuments = (resourceType : string) =>
{
    switch(resourceType) {
        case ResourceTypes.ADHAAR_FILE : return 2;
        case ResourceTypes.DRIVING_LICENSE: return 1;
        case ResourceTypes.PASSPORT: return 2;
        default: return 1;
    }
}