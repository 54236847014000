import img from "./404.svg";
import { Box } from '@mui/material';

function Error() {
  return (
    <Box sx={{width:"80%",
    height:"10%", 
    direction:"column",
    alignItems:"center",
    justifyContent:"center"}}>
      <img src={img} alt="img src..."></img>
    </Box>
  );
}

export default Error;
